<div class="flex-row flex-align-center g05">
  <slot name="visibility" />
  <blockquote class="pl05 full-width" data-test="comment">
    <div class="flex-row flex-align-center g05">
      <div style="width: 15px; height:15px;">
        <slot name="edit" />
      </div>
      <SafeHtml value={messageHtml} />
    </div>
  </blockquote>
</div>

<script>
  import SafeHtml from './SafeHtml.svelte'
  export let messageHtml
</script>

<style lang="scss">
  @import '../../css/helpers';

  blockquote {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 13px;
    background-color: #f1efef;
    border-left: 3px solid $primary;
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(a.user-mention) {
    background-color: $lightgray;
    color: #000;
    border-radius: 2px;
    padding: 0 5px;

    &:hover {
      text-decoration: none;
      color: #000;
    }
  }

  /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
  :global(a.user-mention-me) {
    background-color: $info;
    color: $light;

    &:hover {
      color: $light;
    }
  }
</style>
