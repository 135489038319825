{#if canEditCapacity}
  <Modal {lg} {xl} on:close={onClose}>
    <h4 slot="title" data-test="title">
      {#if capacity.capacityId > 0}
        <div class="small">Opportunity</div>
        {#if isDetailsView}
          {capacity.name}
        {:else}
          <a href="/opportunities/details/{capacity.capacityId}" class="not-initial-focus">{capacity.name}</a>
        {/if}
      {:else if creatingCopy}
        <div class="small">Copying opportunity</div>
        {creatingCopyOriginalName}
      {:else}
        New opportunity
      {/if}
    </h4>
    {#if capacity.allowGroups === undecided && !capacity.capacityId}
      <CapacityTypeSelector bind:allowGroups={capacity.allowGroups} />
    {:else}
      <CapacityForm
        {saving}
        {agreementDisciplines}
        {serviceId}
        {capacity}
        {creatingCopy}
        {onClose}
        {saveCapacity}
        {onAfterCapacitySaved}
        {allowedServiceIds}
        {ancestorOrgsById}
        baseHref={_baseHref}
      />
    {/if}
    <!-- // TODO(phase2-nursing) Show host and guest views?  -->
    <!-- {:else if personaService.canViewCapacityAsHost(capacity)}
      <CapacityHostView {capacity} {capacityGuest} {onClose} baseHref={_baseHref} />
      <CapacityGuestView {capacity} {capacityGuest} baseHref={_baseHref} {onClose} {onUpdate} /> -->
  </Modal>
{:else if capacity}
  <CapacityErrorModal capacityId={capacity.capacityId} {onClose} errorMessage="Opportunity {capacity.capacityId} is not editable." />
{/if}

<script>
  import { RequestAvailabilityPolicy } from 'config/enums.js'
  import api from 'services/api.js'
  import CapacityErrorModal from 'components/CapacityModal.Error.svelte'
  import CapacityForm from 'components/CapacityForm.svelte'
  import CapacityTypeSelector from 'components/CapacityTypeSelector.svelte'
  import getValueFromCollectionBySubpath from 'services/get-value-from-collection-by-subpath.js'
  import Modal from 'components/Modal.svelte'
  import personaService from 'services/persona-service.js'

  export let baseHrefOpportunities
  export let subpath // comes from <Route>.path template
  export let saving
  export let capacities
  export let newCapacityInput = {}
  export let serviceId
  export let allowedServiceIds = []
  export let listBodyIncludes
  export let onClose = _.noop
  export let saveCapacity = _.noop
  export let onAfterCapacitySaved = _.noop
  export let ancestorOrgsById = {}
  export let isDetailsView = false

  export let capacity
  export let capacityId
  export let selectedCapacityIds = []

  let agreementDisciplines
  let creatingCopyOriginalName = null
  let creatingCopy = false
  const undecided = Symbol('Undecided')
  $: if (capacityId) selectedCapacityIds = [capacityId]
  $: if (!capacity) subpath, capacities, setCapacity()
  $: _baseHref = isDetailsView ? `${baseHrefOpportunities}/edit` : `${baseHrefOpportunities}/${subpath}`
  $: canEditCapacity = capacity && personaService.canEditCapacity(capacity)
  $: needsIndividualOrGroupDecision = canEditCapacity && capacity.maxStudentsInGroup === undecided && !capacity.capacityId
  $: xl = canEditCapacity && !needsIndividualOrGroupDecision
  $: lg = !xl

  function setCapacity() {
    if (capacity) return
    const maybeValue = getValueFromCollectionBySubpath(capacities, subpath, 'capacityId', newCapacity, copyCapacity, loadCapacity)
    if (maybeValue) capacity = maybeValue
  }

  async function loadCapacity(capacityId, shouldCopy) {
    const response = await api.capacity.list({ ...listBodyIncludes, selectedCapacityIds: [capacityId], pageSize: 0, excludeTotalCount: true })
    const selected = response.selectedCapacities[0]
    capacity = shouldCopy ? copyCapacity(selected) : selected
  }

  function copyCapacity(capacity) {
    creatingCopy = true
    creatingCopyOriginalName = capacity.name
    const copy = _.cloneDeep(capacity)
    copy.name += ' (copy)'
    copy.capacityId = 0
    copy.rotationCount = 0

    if (copy.guests) {
      let capacityGuestId = -1
      for (const guest of copy.guests) {
        guest.capacityGuestId = capacityGuestId--
        guest.matchCount = 0
        guest.matchCountAvailable = guest.maxMatches
        guest.guaranteedMatchCountRemaining = guest.guaranteedMatches
      }
    }

    if (copy.shifts) {
      let shiftId = -1
      let shiftDayId = -1
      for (const shift of copy.shifts) {
        shift.capacityId = 0
        shift.shiftId = shiftId--
        if (shift.shiftDays) {
          for (const shiftDay of shift.shiftDays) {
            shiftDay.shiftDayId = shiftDayId--
          }
        }
      }
    }

    return copy
  }

  function newCapacity() {
    return {
      serviceId,
      name: '',
      description: '',
      openForScheduling: true,
      postToSearchPage: null,
      allowCoordinatorScheduling: null,
      allowHostCoordinatorScheduling: null,
      allowGuestCoordinatorScheduling: null,
      allowStudentsToLeaveGroupMatch: null,
      noNewMatchesForDayWhenOthersHaveBeen: null,
      allowGroups: undecided,
      startDate: new Date(),
      endDate: null,
      allowStudentScheduling: false,
      allowStudentChanges: false,
      maxMatches: null,
      shifts: [
        {
          name: 'All day',
          shiftId: null,
          description: null,
          maxMatchCountPerDay: null,
          maxStudentsInGroup: null,
          matchDayLimitPerWeek: null,
          startTime: null,
          endTime: null,
          color: 'green',
          shiftDays: [],
          dirty: true,
        },
      ],
      staffIds: [],
      staff: [],
      rotationTypes: [],
      specialties: [],
      disciplines: [],
      studentYears: [],
      requestAvailabilityPolicy: RequestAvailabilityPolicy.MultipleAvailableShifts,
      guests: [],
      ...newCapacityInput,
    }
  }
</script>
