import { formatRelativeName, getParentNames } from 'services/orgs-service.js'

import { PersonaType } from 'config/enums.js'
import { isDevEnvironment } from './environment.js'
import { writable } from 'svelte/store'

const defaultPadding = 20

class IntercomProxy {
  constructor() {
    this.retries = 0
    this.isDevEnvironment = isDevEnvironment
    this.fakeIntercom = this.isDevEnvironment ? writable({}) : null
  }

  boot() {
    if (this.isDevEnvironment) return
    if (this.retries > 10) return
    if (this.isAvailable()) {
      window.Intercom('boot', { app_id: 'rgzta0gl' })
    } else {
      this.retries++
      setTimeout(() => this.boot(), 100)
    }
  }

  isAvailable() {
    return window.Intercom != null
  }

  shutdown() {
    this._invoke('shutdown')
  }

  update() {
    this._invoke('update')
  }

  show() {
    this._invoke('show')
  }

  hide() {
    this._invoke('hide')
  }

  _invoke(method) {
    if (this.isAvailable()) window.Intercom(method)
    else if (this.isDevEnvironment) this.fakeIntercom.set(window.intercomSettings)
  }

  moveUp() {
    window.intercomSettings.vertical_padding = 110
    this.update()
  }

  moveDown() {
    window.intercomSettings.vertical_padding = defaultPadding
    this.update()
  }

  setUser(user) {
    if (user == null) return
    window.intercomSettings = {
      ...window.intercomSettings,
      horizontal_padding: defaultPadding,
      vertical_padding: defaultPadding,
      name: user.name,
      email: user.email,
      user_hash: user.intercomHmacHash,
      avatar: {
        type: 'avatar',
        image_url: user.profilePicture,
      },
      created_at: user.datecreated,
      phone: user.phonePublic,
      email_confirmed: user.emailConfirmed,
      is_student: user.isStudent,
      discipline: user.discipline ?? (user.otherDiscipline == null ? null : `${user.otherDiscipline} (not in system)`),
      ...(user.isStudent
        ? {
            student_year: user.studentYear,
            student_graduation_date: user.studentGraduationDate,
          }
        : {}),

      is_preceptor: user.isPreceptor,
      is_health_coordinator: user.isHealthCoordinator,
      is_health_admin: user.isHealthAdmin,
      is_faculty: user.isFaculty,
      is_school_coordinator: user.isSchoolCoordinator,
      is_school_admin: user.isSchoolAdmin,

      // https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-company-object
      companies:
        user?.personas?.length > 0
          ? user.personas.map(p => ({
              company_id: p.orgId,
              name: p.label,
              // TODO(orgfeatures-2) when persona types are merged, is_health, is_school, and is_consortium should be changed to:
              // p.subscriptionFeatureTypes.includes(FeatureType.<something>)
              is_health: p.personaType === PersonaType.ProviderStaff,
              is_school: p.personaType === PersonaType.SchoolStaff,
              is_consortium: p.personaType === PersonaType.ConsortiumStaff,
              subscriptionStatus: p.subscriptionStatus,
              // TODO: include their feature package name instead maybe...
              // subscriptionFeatures: p.subscriptionFeatureTypes?.map(ft => invertedFeatureType[ft]).join(', ') ?? null, // don't set this--get an error from intercom: STATUS 422 "custom_attributes invalid, length too long: subscriptionFeatures"
              relativeName: formatRelativeName(p.relativeName),
              rootOrgName: getParentNames(p.relativeName)?.[0],
            }))
          : null,
    }

    if (!user.isStudent) {
      delete window.intercomSettings.student_year
      delete window.intercomSettings.student_graduation_date
    }

    this.update()
  }
}

export default new IntercomProxy()
